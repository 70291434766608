import axios from 'axios';
import config from '../config';

const login = async data => {
  localStorage.removeItem('token');
  const res = await axios.post(`${config.API_URL}/api/accounts/login/`, data);
  return res;
};

export default login;
