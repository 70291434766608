import React from 'react';
import Review from './ReviewCard';

const PublicReviews = ({ properties = [] }) => {
  const reviews = properties.reduce((acc, item) => {
    if (!item.reviews?.length) return acc;
    return [...acc, ...item.reviews.map(r => ({ ...r, address: item.address }))];
  }, []);

  console.log({ reviews });

  return (
    <div className="mt-20 grid-cols-2 grid grid-flow-row gap-8">
      {reviews.map(r => (
        <Review review={r} />
      ))}
    </div>
  );
};

export default PublicReviews;
