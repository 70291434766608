import React from 'react';

const Card = ({ children, title, subTitle }) => {
  return (
    <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
      <div className="p-6 space-y-6 sm:p-8">
        <h6 className="text-lg font-bold leading-tight tracking-tight text-gray-900">{title}</h6>
        {subTitle && <h6 className="text-sm !mt-2 leading-tight tracking-tight text-gray-400">{subTitle}</h6>}

        {children}
      </div>
    </div>
  );
};

export default Card;
