import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import requestPasswordReset from '../../api/requestPasswordReset';
import { setFormError } from '../formFields/helper.js';
import Layout from './Layout';
import Card from './Card';
import { TextField } from '../formFields';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const methods = useForm();
  const { errors } = methods?.formState;
  const serverError = errors?.root?.serverError;

  const { mutate, isLoading } = useMutation(requestPasswordReset, {
    onSuccess: () => {
      console.log('SUCCESS');
      navigate('/reset-password', { state: { email: methods.getValues('email') } });
    },
    onError: error => setFormError({ error, methods })
  });

  return (
    <Layout>
      <Card title="Forgot password?">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(mutate)} className="space-y-2 !mt-4">
            <TextField label="Email" name="email" placeholder="Enter your email" />

            <div className="py-3">
              <button
                type="submit"
                className="w-full text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                disabled={isLoading}
              >
                Reset password
              </button>
              {serverError?.message && <div className="text-red-600 text-sm mt-2">{serverError.message}</div>}
            </div>
            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
              Go back to{' '}
              <Link to="/login" className="text-sm font-medium text-indigo-600 hover:underline">
                Sign in
              </Link>
            </p>
          </form>
        </FormProvider>
      </Card>
    </Layout>
  );
};

export default ForgotPassword;
