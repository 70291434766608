import axios from 'axios';

const init = () => {
  axios.interceptors.request.use(
    config => {
      const token = localStorage.getItem('token');
      if (token) config.headers['Authorization'] = `Token ${token}`;
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.response.status === 401) {
        localStorage.removeItem('token');
      }
      //console.log(error.response.status);
      return Promise.reject(error);
    }
  );
};

export { init };
