import React from 'react';
import { useFormContext } from 'react-hook-form';

const AmenitiesSelector = props => {
  const { label, name, rootClass, amenities, ...other } = props;
  const { register, getFieldState, formState } = useFormContext();
  const { error, ...oth } = getFieldState(name, formState);
  if (!amenities?.length) return null;

  return (
    <div className={rootClass}>
      <div className="block mb-4 text-sm font-medium text-gray-900 dark:text-white">{label}</div>
      <div className="grid grid-cols-1 lg:grid-cols-3">
        {amenities.map(a => (
          <div key={a.id} className="rounded-md relative mx-4 mb-4">
            <label className="hover:cursor-pointer mb-0 pl-2 font-medium text-left flex items-start">
              {/* <div style={{ backgroundImage: `url(${a.icon_url})` }} className="absolute left-0 w-[32px] h-[32px] hidden grayscale bg-contain bg-no-repeat bg-center"></div> */}
              <input className="scale-125 mr-3 mt-1" type="checkbox" value={a.id} {...other} {...register(name)} />
              <div className="text-xs w-full">{a.name}</div>
            </label>
          </div>
        ))}
      </div>
      {error?.message && <span className="text-red-600 text-sm">{error.message}</span>}
    </div>
  );
};

export default AmenitiesSelector;
