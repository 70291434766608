import React from 'react';
import { useFormContext } from 'react-hook-form';

const TextField = (props, ref) => {
  const { label, name, rootClass, options, isTextarea, ...other } = props;
  const { register, getFieldState, formState } = useFormContext();
  const { error } = getFieldState(name, formState);

  const className = `bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 outline-none ${
    error?.message || error?.type === 'required' ? 'border-red-600 focus:ring-red-600 focus:border-red-600' : 'focus:ring-indigo-600 focus:border-indigo-600'
  }`;

  return (
    <div className={rootClass}>
      <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        {label}
      </label>

      {isTextarea ? (
        <textarea type="text" name={name} id={name} className={className} {...register(name, options || {})} {...other} />
      ) : (
        <input type="text" name={name} id={name} className={className} {...register(name, options || {})} {...other} />
      )}

      {error?.message && <span className="text-red-600 text-sm">{error.message}</span>}
    </div>
  );
};

const SelectField = props => {
  const { label, name, rootClass, options, ...other } = props;
  const { register, getFieldState, formState } = useFormContext();
  const { error } = getFieldState(name, formState);

  return (
    <div className={rootClass}>
      <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        {label}
      </label>
      <select
        id={name}
        {...register(name)}
        {...other}
        className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5 ${error?.message ? 'border-red-600' : ''}`}
      >
        {options.map(o => (
          <option key={o.value} value={o.value}>
            {o.name}
          </option>
        ))}
      </select>
      {error?.message && <span className="text-red-600 text-sm">{error.message}</span>}
    </div>
  );
};

export { TextField, SelectField };
