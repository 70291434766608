import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { AiFillStar } from 'react-icons/ai';
import { Rating, Star } from '@smastrom/react-rating';
import { parseISO, formatRelative } from 'date-fns';

const ReviewCard = ({ review }) => {
  const { created_by: name, created_at, text, address, rating } = review;
  const relativeCreationDate = formatRelative(parseISO(created_at), new Date());
  // TODO: maybe pass current timestamp as a prop, to avoid creating new Date() with every review

  return (
    <div className="p-2 flex border border-0 border-l-4 bg-slate-50 shadow-md border-orange-600">
      <div style={{ backgroundImage: `url("https://randomuser.me/api/portraits/women/${Math.floor(Math.random() * 50) + 1}.jpg")` }} className=" bg-center bg-cover h-[72px] rounded-md flex-[0_0_72px]" />

      <div className="w-[172px] px-4">
        <div className="text-lg font-semibold">{name}</div>
        <div className="text-sm italic">{relativeCreationDate}</div>
        <div className="flex items-start gap-x-1">
          <FaMapMarkerAlt className="pt-1.5" /> {address?.street}
        </div>
      </div>
      <div className="flex-1">
        <Rating readOnly style={{ maxWidth: 120 }} value={rating} itemStyles={{ itemShapes: Star, activeFillColor: '#facc15', inactiveFillColor: '#e5e5e5' }} />
        <p className="text-lg">{text}</p>
      </div>
    </div>
  );
};

export default ReviewCard;
