import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import Layout from './Layout';
import Card from './Card';
import signup from '../../api/signup';
import { TextField } from '../formFields';
import { setFormError } from '../formFields/helper.js';

const Signup = () => {
  const navigate = useNavigate();
  const methods = useForm();
  const { errors } = methods?.formState;
  const serverError = errors?.root?.serverError;

  const { mutate, isLoading } = useMutation(signup, {
    onSuccess: () => {
      navigate('/verify-code', { state: { email: methods.getValues('email') } });
    },
    onError: error => setFormError({ error, methods })
  });

  return (
    <Layout>
      <Card title="Create Your Account">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(mutate)} className="space-y-3">
            <TextField label="First Name" name="first_name" placeholder="John" />
            <TextField label="Last Name" name="last_name" placeholder="Doe" />
            <TextField label="Email" name="email" placeholder="email@website.com" />
            <TextField label="Password" name="password" type="password" placeholder="••••••••" />

            <div className="py-3">
              <button
                type="submit"
                className="w-full text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                disabled={isLoading}
              >
                {isLoading ? 'Creating...' : 'Create Account'}
              </button>
              {serverError?.message && <div className="text-red-600 text-sm mt-2">{serverError.message}</div>}
            </div>
            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
              Already have an account?{' '}
              <Link to="/login" className="text-sm font-medium text-indigo-600 hover:underline">
                Sign in
              </Link>
            </p>
          </form>
        </FormProvider>
      </Card>
    </Layout>
  );
};

export default Signup;
