import axios from 'axios';
import config from '../config';

const myProperties = async () => {
  try {
    const res = await axios.get(`${config.API_URL}/api/properties/my-properties/`);
    return res.data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export default myProperties;
