import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useLocation, Link } from 'react-router-dom';
import { HiBadgeCheck } from 'react-icons/hi';

import Layout from './Layout';
import Card from './Card';
import emailVerification from '../../api/emailVerification';
import resendEmailVerification from '../../api/resendEmailVerification';
import { TextField } from '../formFields';
import { setFormError } from '../formFields/helper.js';

const VerifyCode = () => {
  const { state } = useLocation();
  const [codeVerified, setCodeVerified] = useState();
  const [resendCode, setResendCode] = useState();
  const email = state?.email;
  const methods = useForm({ defaultValues: { email } });
  const { errors } = methods?.formState;
  const serverError = errors?.root?.serverError;

  const { mutate, isLoading } = useMutation(emailVerification, {
    onSuccess: () => setCodeVerified(true),
    onError: error => setFormError({ error, methods })
  });

  const { mutate: mutateResend, isLoading: isResending } = useMutation(resendEmailVerification, {
    onSuccess: () => setResendCode(true)
  });

  const handleClickResend = () => mutateResend({ email });

  if (codeVerified) {
    return (
      <Layout>
        <Card>
          <HiBadgeCheck size={42} className="text-green-600 mx-auto" />
          <p className="text-center text-gray-600">Thank you for verifying your email address. We're excited to have you as a member of our community!</p>

          <Link
            to="/login"
            className="w-full mx-auto block text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Sign In to your account
          </Link>
        </Card>
      </Layout>
    );
  }

  return (
    <Layout>
      <Card title="Please check your email" subTitle={email && `We've send a code to ${email}`}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(mutate)} className="space-y-3 mb-0">
            <TextField name="code" placeholder="XXXXX" />

            <div className="py-3">
              <button
                type="submit"
                className="w-full text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                disabled={isLoading}
              >
                {isLoading ? 'Verifying...' : 'Verify'}
              </button>
              {serverError?.message && <div className="text-red-600 text-sm mt-2">{serverError.message}</div>}
            </div>
          </form>

          {!resendCode && (
            <p className="text-sm font-light text-gray-500  !mt-0">
              {isResending ? (
                'Resending...'
              ) : (
                <>
                  Didn't get a code?{' '}
                  <button onClick={handleClickResend} className="text-sm font-medium  underline">
                    Click to resend
                  </button>
                </>
              )}
            </p>
          )}

          {resendCode && <p className="text-sm font-light text-gray-600 !mt-0">A verification code sent!</p>}
        </FormProvider>
      </Card>
    </Layout>
  );
};

export default VerifyCode;
