import React from 'react';
import { Link } from 'react-router-dom';

const Button = ({ children, isLink, className, isOutlined, ...other }) => {
  const primaryClass = 'w-full text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ';
  const outlineClass = 'w-full text-indigo-600 hover:text-white border border-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ';

  const classNameMerged = (isOutlined ? outlineClass : primaryClass) + className;

  if (isLink)
    return (
      <Link className={classNameMerged} {...other}>
        {children}
      </Link>
    );

  return (
    <button className={classNameMerged} {...other}>
      {children}
    </button>
  );
};

export default Button;
