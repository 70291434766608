import React, { createContext, useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import config from '../config';
import userREST from '../api/user';

const DEFAULT_STATE = {
  user: {},
  isLoggedIn: false,
  isLoggingIn: true
};

export const UserContext = createContext(DEFAULT_STATE);

export const useUserContext = () => {
  const data = useContext(UserContext);
  return { ...data };
};

const UserProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(DEFAULT_STATE.isLoggedIn);
  const [isLoggingIn, setIsLogginIn] = useState(!!localStorage.getItem('token'));
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [user, setUser] = useState();

  const logUserIn = token => {
    localStorage.setItem('token', token);
    setToken(token);
    setIsLogginIn(true);
  };
  const logUserOut = async () => {
    setIsLoggedIn(false);
    setIsLogginIn(false);
    setUser(null);
    setToken(null);
    await axios.delete(`${config.API_URL}/api/accounts/logout/`);
    localStorage.removeItem('token');
  };

  const handleOnError = err => {
    if (err.response.status === 401) {
      logUserOut();
      console.log('Log user out', err.response.status);
    }
    setIsLogginIn(false);
  };

  const handleOnSuccess = user => {
    if (!user) return logUserOut();
    setIsLoggedIn(true);
    setUser(user);
    setIsLogginIn(false);
  };

  // const cacheTime = 0;
  // const staleTime = 0;
  const key = ['user', token];
  const _ = useQuery(key, userREST, { onSuccess: handleOnSuccess, onError: handleOnError, retry: false, enabled: !!token });
  return (
    <UserContext.Provider
      value={{
        ...DEFAULT_STATE,
        isLoggedIn,
        logUserIn,
        logUserOut,
        isLoggingIn,
        user
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
