import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUserContext } from './context/user';
import Loader from './components/Loader';

const PrivateRoute = ({ children }) => {
  const { isLoggedIn, isLoggingIn } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggingIn) return;
    if (!isLoggedIn) navigate('/login');
  }, [isLoggedIn, isLoggingIn, navigate]);

  if (isLoggingIn) return <Loader />;
  return <>{children}</>;
};

export default PrivateRoute;
