import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { capitalCase } from 'change-case';
import { AddressAutofill } from '@mapbox/search-js-react';

import Layout from '../LayoutDashboard';
import { setFormError } from '../formFields/helper.js';
import { SelectField, TextField } from '../formFields';
import addProperty from '../../api/addProperty';
import getAmenities from '../../api/getAmenities';
import Button from '../Button';
import { PROPERTY_TYPES, OWNER_TYPES } from '../../constants';
import AmenitiesSelector from '../formFields/AmenitiesSelector';
import config from '../../config.js';

const propertyTypeOptions = Object.keys(PROPERTY_TYPES).map(key => ({ name: capitalCase(key), value: PROPERTY_TYPES[key] }));
const ownerTypeOptions = Object.keys(OWNER_TYPES).map(key => ({ name: capitalCase(key), value: OWNER_TYPES[key] }));

const FormAddProperty = () => {
  const cacheTime = 60 * 60 * 1000;
  const staleTime = 10 * 60 * 1000;
  const { data: amenities } = useQuery(['amenities'], getAmenities, { cacheTime, staleTime });
  const methods = useForm();
  const { errors } = methods?.formState;
  const serverError = errors?.root?.serverError;
  const [mapboxAddress, setMapboxAddress] = useState();
  const [showSuccess, setShowSuccess] = useState();

  const { mutate, isLoading } = useMutation(addProperty, {
    onSuccess: res => {
      setShowSuccess(true);
    },
    onError: error => setFormError({ error, methods })
  });

  const submit = data => {
    const formData = {
      owner_type: data.ownerType,
      property_type: data.propertyType,
      amenity_ids: data.amenities,
      unit: data['address-2'],
      address: {
        street: mapboxAddress['address_line1'],
        city: data.city,
        state: data.state,
        country: mapboxAddress['country'],
        postal: data.postalCode,
        mapbox_id: Number(mapboxAddress.id?.split('.')[1]),
        coordinates: mapboxAddress.coordinates
      }
    };
    mutate(formData);
  };

  const handleOnAddressRetrieve = data => {
    const coordinates = {
      lat: data?.features[0]?.geometry.coordinates[1],
      lng: data?.features[0]?.geometry.coordinates[0]
    };
    setMapboxAddress({ ...data?.features[0]?.properties, coordinates });
  };

  const handleAddAnotherProperty = () => {
    methods.reset();
    setShowSuccess(false);
  };

  if (showSuccess) {
    return (
      <Layout>
        <h4 className="text-center text-xl mt-32">Great! Your property is now live on RentNation. 🎉</h4>

        <div className="flex mt-12 max-w-[420px] mx-auto">
          <Button className="mr-8" onClick={handleAddAnotherProperty}>
            Add Another Property
          </Button>

          <Button isLink to="/properties/add">
            Leave a Review
          </Button>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <h2 className="text-center text-lg my-8">Add New Property</h2>
      <div className="rounded-lg shadow border md:max-w-2xl p-8 mx-auto">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(submit)} className="space-y-4">
            {/* <TextField label="Address" name="address" placeholder="Start typing an address, e.g. 135 main..." /> */}

            <AddressAutofill accessToken={config.MAPBOX_ACCESS_TOKEN} onRetrieve={handleOnAddressRetrieve}>
              <TextField label="Address" type="text" name="address-1" autoComplete="address-line1" />

              <div className="grid grid-cols-2 gap-4 mt-2">
                <TextField label="Unit" type="text" name="address-2" autoComplete="address-line2" options={{ required: true }} />
                <TextField label="City" type="text" name="city" autoComplete="address-level2" readOnly={true} />
              </div>

              <div className="grid grid-cols-2 gap-4 mt-2">
                <TextField label="State" type="text" name="state" autoComplete="address-level1" readOnly={true} />
                <TextField label="postal code" type="text" name="postalCode" autoComplete="postal-code" readOnly={true} />
              </div>
            </AddressAutofill>

            <SelectField label="Property Type" name="propertyType" options={propertyTypeOptions} placeholder="Property Type" />
            <SelectField label="Owner Type" name="ownerType" options={ownerTypeOptions} placeholder="Owner Type" />
            <AmenitiesSelector amenities={amenities} name="amenities" label="Select Amenities" />

            <Button type="submit">{isLoading ? 'Saving...' : 'Save'} </Button>

            {serverError?.message && <div className="text-red-600 text-sm mt-2">{serverError.message}</div>}
          </form>
        </FormProvider>
      </div>
    </Layout>
  );
};

export default FormAddProperty;
