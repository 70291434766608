import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { RiLoader2Line } from 'react-icons/ri';

import myProperties from '../../api/myProperties';
import Button from '../Button';

import Layout from '../LayoutDashboard';
import PropertyCard from './PropertyCard';

const Properties = () => {
  const cacheTime = 60 * 60 * 1000;

  const { data: properties, isLoading, isFetching, isError } = useQuery(['myProperties'], myProperties, { cacheTime, staleTime: 0 });

  console.log(properties);

  return (
    <Layout>
      <div className="text-center text-lg">
        {!isLoading && !properties?.length ? (
          <div className="mt-8">
            <div>You haven't created any properties yet.</div>

            <Button isLink to="/properties/add" className="mt-8 block max-w-[250px] mx-auto">
              Create Your First Property
            </Button>
          </div>
        ) : null}

        {isLoading && !properties?.length ? (
          <div className="mt-32">
            <div>Loading properties, please wait...</div>
            <RiLoader2Line size={32} className="animate-spin mx-auto mt-8" />
          </div>
        ) : null}

        {properties?.length ? (
          <div className="mt-4">
            <div className="flex items-center">
              <div>
                Property Listings ({properties.length}) {isLoading || isFetching ? <RiLoader2Line size={22} className="animate-spin inline" /> : null}
              </div>

              <Button isLink to="/properties/add" className=" max-w-[172px] ml-auto">
                Add New Property
              </Button>
            </div>

            <div className="mt-8 max-h-[84vh] overflow-y-scroll pr-2">
              {properties.map((property, idx) => (
                <PropertyCard key={idx} property={property} />
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </Layout>
  );
};

export default Properties;
