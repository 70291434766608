import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../svgs/Logo';

const Layout = ({ children }) => {
  return (
    <section className=" bg-orange-50 h-screen overflow-scroll pb-28">
      <div className="flex flex-col items-center justify-start pt-[14vh] px-6 mx-auto">
        <Link to="/">
          <Logo size={172} className="mb-4" />
        </Link>
        {children}
      </div>
    </section>
  );
};

export default Layout;
