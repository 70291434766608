import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { HiBadgeCheck } from 'react-icons/hi';

import passwordReset from '../../api/passwordReset';
import { setFormError } from '../formFields/helper.js';
import Layout from './Layout';
import Card from './Card';
import { TextField } from '../formFields';

const ResetPassword = () => {
  const { state } = useLocation();
  const email = state?.email;
  const methods = useForm({ defaultValues: { email } });
  const { errors } = methods?.formState;
  const serverError = errors?.root?.serverError;
  const [resetSuccess, setResetSuccess] = useState();

  const { mutate, isLoading } = useMutation(passwordReset, {
    onSuccess: () => setResetSuccess(true),
    onError: error => setFormError({ error, methods })
  });

  if (resetSuccess) {
    return (
      <Layout>
        <Card>
          <HiBadgeCheck size={42} className="text-green-600 mx-auto" />
          <p className="text-center text-gray-600">Your password has been reset</p>

          <Link
            to="/login"
            className="w-full mx-auto block text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Sign In to your account
          </Link>
        </Card>
      </Layout>
    );
  }

  return (
    <Layout>
      <Card title="Set new password">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(mutate)} className="space-y-2 !mt-4">
            <TextField label="Email" name="email" placeholder="Enter your email" />
            <TextField label="Code" name="code" placeholder="Enter the code we sent to your email" />
            <TextField label="New Password" name="password" type="password" placeholder="********" />

            <div className="py-3">
              <button
                type="submit"
                className="w-full text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                disabled={isLoading}
              >
                Submit
              </button>
              {serverError?.message && <div className="text-red-600 text-sm mt-2">{serverError.message}</div>}
            </div>
            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
              Go back to{' '}
              <Link to="/login" className="text-sm font-medium text-indigo-600 hover:underline">
                Sign in
              </Link>
            </p>
          </form>
        </FormProvider>
      </Card>
    </Layout>
  );
};

export default ResetPassword;
