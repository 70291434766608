import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import Layout from './Layout';
import { setFormError } from '../formFields/helper.js';
import Card from './Card';
import { TextField } from '../formFields';
import login from '../../api/login';
import { useUserContext } from '../../context/user';

const Login = () => {
  const { logUserIn, isLoggingIn } = useUserContext();
  const methods = useForm();
  const { errors } = methods?.formState;
  const serverError = errors?.root?.serverError;

  const { mutate, isLoading } = useMutation(login, {
    onSuccess: res => {
      const { token } = res?.data || {};
      if (token) logUserIn(token);
    },
    onError: error => setFormError({ error, methods })
  });

  return (
    <Layout>
      <Card title="Sign in to your account">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(mutate)} className="space-y-4">
            <TextField label="Your Email" name="email" placeholder="email@website.com" />
            <TextField label="Password" name="password" type="password" placeholder="••••••••" />

            <div className="text-right">
              <Link to="/forgot-password" className="text-sm font-medium text-indigo-600 hover:underline">
                Forgot password?
              </Link>
            </div>

            <button
              type="submit"
              className="w-full text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              disabled={isLoading}
            >
              {isLoggingIn ? 'Signing in...' : 'Sign in'}
            </button>
            {serverError?.message && <div className="text-red-600 text-sm mt-2">{serverError.message}</div>}
            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
              Don’t have an account yet?{' '}
              <Link to="/signup" className="text-sm font-medium text-indigo-600 hover:underline">
                Sign up
              </Link>
            </p>
          </form>
        </FormProvider>
      </Card>
    </Layout>
  );
};

export default Login;
