import React from 'react';
import { FiSettings, FiHelpCircle } from 'react-icons/fi';
import { MdOutlineHomeWork, MdOutlineRateReview } from 'react-icons/md';
import { RxDashboard } from 'react-icons/rx';

import { Link } from 'react-router-dom';
import Logo from './svgs/Logo';
import { useUserContext } from '../context/user';

const LayoutDashboard = ({ children }) => {
  const { logUserOut, user } = useUserContext();
  const { first_name } = user || {};
  return (
    <div className=" h-[100vh] overflow-auto">
      <div className="border border-0 bg-orange-50 border-b-2 border-gray-200">
        <div className="container mx-auto h-16 flex items-center justify-between">
          <Link to="/">
            <Logo size={172} className="" />
          </Link>
          <div className="flex items-center">
            <div className="whitespace-nowrap mr-4">Welcome, {first_name}! </div>
            <button onClick={logUserOut} className="w-full text-indigo-600  hover:bg-indigo-50  font-medium rounded-lg text-sm px-5 py-2.5 text-center">
              Logout
            </button>
          </div>
        </div>
      </div>
      <div className="flex container mx-auto bg-white" style={{ height: 'calc(100vh - 64px)' }}>
        <div className="border border-0 border-r-2 border-gray-200 w-[272px] pt-4">
          <Link to="/dashboard" className="flex items-center  w-full px-2  py-4 font-light text-gray-600 hover:bg-gray-50">
            <RxDashboard size={24} className="mr-2" />
            Dashboard
          </Link>
          <Link to="/properties" className="flex items-center  w-full px-2  py-4 font-light text-gray-600 hover:bg-gray-50">
            <MdOutlineHomeWork size={24} className="mr-2" />
            Properties
          </Link>
          <Link to="/reviews" className="flex items-center  w-full px-2  py-4 font-light text-gray-600 hover:bg-gray-50">
            <MdOutlineRateReview size={24} className="mr-2" />
            Reviews
          </Link>
          <div className="border border-0 border-b-2 border-gray-200 mr-2 my-4"></div>
          <Link to="/settings" className="flex items-center  w-full px-2  py-4 font-light text-gray-600 hover:bg-gray-50">
            <FiSettings size={24} className="mr-2" />
            Settings
          </Link>
          <Link to="/help" className="flex items-center  w-full px-2  py-4 font-light text-gray-600 hover:bg-gray-50">
            <FiHelpCircle size={24} className="mr-2" />
            Help
          </Link>
        </div>

        <div className="p-4 flex-1 overflow-auto">{children}</div>
      </div>
    </div>
  );
};

export default LayoutDashboard;
