import React from 'react';
import Layout from './LayoutDashboard';

import Button from './Button';

const Dashboard = () => {
  return (
    <Layout>
      <p className="text-center text-lg mt-4">
        Ready to showcase your new property to the world? 🌍
        <br /> Let's get started and add your fantastic property to our listings!{' '}
      </p>
      <div className="text-center mt-8">
        <Button isLink to="/properties/add">
          Add New Property
        </Button>
      </div>
    </Layout>
  );
};

export default Dashboard;
