import axios from 'axios';
import queryString from 'query-string';

import config from '../config';

const properties = async ({ queryKey, ...other }) => {
  const [key, stringParams] = queryKey;
  const params = queryString.parse(stringParams);
  try {
    const res = await axios.get(`${config.API_URL}/api/properties/`, { params });
    return res.data?.results;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export default properties;
