import React from 'react';
import { BsGearWideConnected } from 'react-icons/bs';

const Loader = () => {
  return (
    <div className="flex items-center justify-center h-[100vh] bg-orange-50 flex-col">
      <BsGearWideConnected size={32} className="animate-spin" />
      <p className="mt-2">Gearing up for your experience...</p>
    </div>
  );
};

export default Loader;
