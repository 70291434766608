import React, { useEffect, useState } from 'react';
import { AddressMinimap as AM } from '@mapbox/search-js-react';

import config from '../config';

const AddressMinimap = ({ coordinates }) => {
  const [showMap, setShowMap] = useState(false); // Defer rendering the map to fix the bug in mapbox lib

  useEffect(() => {
    const id = setTimeout(() => {
      setShowMap(true);
    }, 1);
    return () => {
      clearTimeout(id);
    };
  }, []);

  if (!coordinates) return null;

  return <AM feature={{ geometry: { coordinates: [coordinates?.lng, coordinates?.lat] } }} keepMarkerCentered show={showMap} accessToken={config.MAPBOX_ACCESS_TOKEN} />;
};

export default AddressMinimap;
