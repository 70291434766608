import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { Rating, RoundedStar } from '@smastrom/react-rating';

import Layout from '../LayoutDashboard';
import { setFormError } from '../formFields/helper.js';
import { TextField } from '../formFields';
import addReview from '../../api/addReview';
import Button from '../Button';
import AddressMinimap from '../AddressMinimap.js';

const FormAddReview = () => {
  const { state } = useLocation();
  const { property } = state || {};

  const methods = useForm();
  const { errors } = methods?.formState;
  const serverError = errors?.root?.serverError;
  const [rating, setRating] = useState(3);
  const [showSuccess, setShowSuccess] = useState();

  const { unit, address } = property || {};

  const { mutate, isLoading } = useMutation(addReview, {
    onSuccess: res => {
      setShowSuccess(true);
    },
    onError: error => setFormError({ error, methods })
  });

  const submit = ({ text }) => {
    mutate({ propertyId: property.id, text, rating, verification_source: 'EMAIL' });
  };

  const handleAddAnotherReview = () => {
    methods.reset();
    setShowSuccess(false);
  };

  if (!property) {
    return (
      <Layout>
        <h4 className="text-center text-xl mt-32">Sorry, the property you're trying to review doesn't exist or is no longer available.</h4>

        <div className="text-center mt-8">
          <Button isLink isOutlined to="/properties/" className="mx-auto">
            Return to Property Listings
          </Button>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <h2 className=" text-lg my-8">Add Review</h2>

      <div className="border border-gray-300 rounded mb-6 flex p-4">
        <div className="w-[220px] h-[320px] rounded-lg overflow-hidden">
          <AddressMinimap coordinates={address?.coordinates} />
        </div>

        <div className="pl-4 text-left flex-1 flex flex-col">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(submit)}>
              <div className="text-lg font-medium mt-2">{address?.street}</div>
              <div className="text-sm mb-2">
                {unit ? 'Unit ' + unit + ', ' : null}
                {address?.city}, {address?.country} {address?.postal}
              </div>

              {showSuccess ? (
                <div className="mt-14">
                  <h4 className="text-xl py-2 bg-green-100 border border-green-400 text-center rounded-lg">Great! Your review is now live on RentNation. 🎉</h4>

                  <Button className="block mx-auto mt-4 max-w-[320px]" onClick={handleAddAnotherReview}>
                    Add Another Review
                  </Button>
                </div>
              ) : (
                <>
                  <Rating style={{ maxWidth: 400 }} value={rating} halfFillMode="svg" onChange={setRating} itemStyles={{ itemShapes: RoundedStar, activeFillColor: '#ffb700', inactiveFillColor: '#fbf1a9' }} />

                  <TextField placeholder="Leave your thoughts about this property..." label="Your Review" type="text" name="text" autoComplete="address-line2" options={{ required: true }} isTextarea />

                  <Button type="submit" className="mt-2">
                    {isLoading ? 'Saving...' : 'Submit'}{' '}
                  </Button>

                  {serverError?.message && <div className="text-red-600 text-sm mt-2">{serverError.message}</div>}
                </>
              )}
            </form>
          </FormProvider>
        </div>
      </div>
    </Layout>
  );
};

export default FormAddReview;
