import React from 'react';
import format from 'date-fns/format';

import AddressMinimap from '../AddressMinimap.js';
import Button from '../Button';

const PropertyCard = ({ property }) => {
  const { created_at, owner_type, property_type, unit, address, amenity_ids } = property || {};

  return (
    <div className="border border-gray-300 rounded mb-6 flex p-4">
      <div className="w-[180px] h-[160px] rounded-lg overflow-hidden">
        <AddressMinimap coordinates={address.coordinates} />
      </div>

      <div className="pl-4 text-left flex-1 flex flex-col">
        <div className="text-lg font-medium mt-2">{address?.street}</div>
        <div className="text-sm mb-2">
          {unit ? 'Unit ' + unit + ', ' : null}
          {address?.city}, {address?.country} {address?.postal}
        </div>
        <div className="mt-auto mb-2">
          <Button isOutlined isLink to="/reviews/add" state={{ property }}>
            Leave a Review
          </Button>
          <Button className="ml-2" isOutlined isLink to="">
            Edit Property
          </Button>
        </div>
      </div>

      <div className="mt-2">
        <div className="text-sm text-gray-600">Added on: {format(new Date(created_at), 'LLL do, yyyy')}</div>
      </div>
    </div>
  );
};

export default PropertyCard;
