import { useQuery } from '@tanstack/react-query';
import { isPointInPolygon } from 'geolib';
import queryString from 'query-string';
import React, { useState } from 'react';
import { FaCanadianMapleLeaf } from 'react-icons/fa';

import propertiesREST from '../api/properties';
import Header from './Header.js';
import InfoCard from './InfoCard.js';
import Mapbox from './Mapbox';
import Reviews from './reviews/PublicReviews';

const Landing = () => {
  const [bounds, setBounds] = useState();

  const cacheTime = 60 * 60 * 1000;
  const staleTime = 10 * 60 * 1000;
  const key = ['properties', queryString.stringify(bounds)];
  const { data: properties } = useQuery(key, propertiesREST, { cacheTime, staleTime });

  const handleMapBoundChange = newBounds => {
    setBounds(newBounds);
  };

  const { ne_lat, ne_lng, sw_lat, sw_lng } = bounds || {};
  const polygon = [
    { latitude: ne_lat, longitude: ne_lng }, // NE
    { latitude: sw_lat, longitude: ne_lng }, // SE
    { latitude: sw_lat, longitude: sw_lng }, // SW
    { latitude: ne_lat, longitude: sw_lng } // NW
  ];

  const filteredProperties = properties?.filter(p => {
    const point = { latitude: p.address.coordinates.lat, longitude: p.address.coordinates.lng };
    const isInvalidPolygon = polygon.find(p => !p.latitude || !p.longitude); // If has empty lat/lng
    if (isInvalidPolygon) return false;
    const res = isPointInPolygon(point, polygon);
    return res;
  });

  return (
    <div className="">
      <div className="container mx-auto">
        <Header />
        <InfoCard />

        <div className="mt-12">
          <h4 className="text-xl my-4 border border-0 border-b-2 border-gray-800 pb-1">Uncover Insights and Experience</h4>
          <Mapbox properties={properties} onMapBoundsChange={handleMapBoundChange} />
          <Reviews properties={filteredProperties} />
        </div>
      </div>

      <div className="flex flex-col items-center my-12">
        <span className="">Proudly Crafted in the Heart of Toronto</span>
        <FaCanadianMapleLeaf size={20} className="text-rose-600" />
      </div>
    </div>
  );
};

export default Landing;
