import React from 'react';
import Layout from './LayoutDashboard';

const Settings = () => {
  return (
    <Layout>
      <div className="text-center text-lg my-8">
        Settings...
        <br />
        <br />
        <br />
        <br />
        Small Team, Big Dreams! <br /> We're working hard to make this awesome!
        <br /> Thank you for your support! <br /> 🙌
      </div>
    </Layout>
  );
};

export default Settings;
