export const PROPERTY_TYPES = {
  APARTMENT: 'APARTMENT',
  DETACHED: 'DETACHED',
  SEMI_DETACHED: 'SEMI_DETACHED',
  CONDOS: 'CONDOS',
  TOWNHOUSES: 'TOWNHOUSES',
  DUPLEXES: 'DUPLEXES',
  SPLIT_LEVELS: 'SPLIT_LEVELS',
  BUNGALOWS: 'BUNGALOWS'
};

export const OWNER_TYPES = {
  INDIVIDUAL: 'INDIVIDUAL',
  RENTAL_CORP: 'RENTAL_CORP'
};
