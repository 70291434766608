import React from 'react';
import Map, { GeolocateControl, Marker } from 'react-map-gl';
import { FaMapMarkerAlt } from 'react-icons/fa';
import 'mapbox-gl/dist/mapbox-gl.css';

import config from '../config';
const DEFAULT_LAT_LONG = [43.653226, -79.383184];

const Mapbox = ({ properties = [], onMapBoundsChange }) => {
  const getBounds = map => {
    const bounds = map.getBounds();
    const ne_lat = bounds.getNorthEast().lat;
    const ne_lng = bounds.getNorthEast().lng;
    const sw_lat = bounds.getSouthWest().lat;
    const sw_lng = bounds.getSouthWest().lng;
    return { ne_lat, ne_lng, sw_lat, sw_lng };
  };

  const handleOnMapChange = ({ viewState, target: map }) => {
    onMapBoundsChange && onMapBoundsChange(getBounds(map));
  };

  const handleOnLoad = ({ target: map }) => {
    onMapBoundsChange && onMapBoundsChange(getBounds(map));
  };

  const uniqAddresses = properties.reduce((acc, p) => {
    const addressExists = !!acc.find(a => a.id === p.address.id);
    if (addressExists) return acc;
    return [...acc, p.address];
  }, []);

  const reviewCounts = uniqAddresses.map(a => {
    const propertiesOnAddress = properties.filter(p => p.address?.id === a.id);
    const totalReviews = propertiesOnAddress.reduce((acc, p) => acc + (p.reviews?.length || 0), 0);
    return { addressId: a.id, totalReviews };
  });

  return (
    <div className="h-[50vh]">
      <Map
        onLoad={handleOnLoad}
        onZoomEnd={handleOnMapChange}
        onDragEnd={handleOnMapChange}
        onRotateEnd={handleOnMapChange}
        mapboxAccessToken={config.MAPBOX_ACCESS_TOKEN}
        initialViewState={{
          longitude: DEFAULT_LAT_LONG[1],
          latitude: DEFAULT_LAT_LONG[0],
          zoom: 12
        }}
        mapStyle="mapbox://styles/mapbox/streets-v11"
      >
        <GeolocateControl positionOptions={{ enableHighAccuracy: true }} trackUserLocation={true} />

        {uniqAddresses &&
          uniqAddresses.map(a => {
            const { lat, lng } = a.coordinates || {};
            if (!lat || !lng) return null;
            const reviewCount = reviewCounts.find(r => r.addressId === a.id);
            const totalReviews = reviewCount?.totalReviews || 0;
            return (
              <Marker key={a.id} longitude={lng} latitude={lat} anchor="bottom">
                <FaMapMarkerAlt size={32} className="text-red-500" />
                {totalReviews ? (
                  <span className="rounded-full bg-red-500  text-white flex items-center justify-center text-xs absolute top-[0px] left-[5px] w-[22px] h-[22px]">
                    {totalReviews}
                  </span>
                ) : null}
              </Marker>
            );
          })}
      </Map>
    </div>
  );
};

export default Mapbox;
