import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { init as axiosInit } from './axiosInterceptor';
import UserProvider from './context/user';
import '@smastrom/react-rating/style.css';

import './index.css';
import '@smastrom/react-rating/style.css';
import Routes from './Routes';

axiosInit();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryClientProvider client={queryClient}>
    <UserProvider>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </UserProvider>
  </QueryClientProvider>
);
