const setFormError = ({ error, methods }) => {
  const { setError } = methods;
  const { errors } = error?.response?.data || {};
  if (!errors?.length) return;
  errors.forEach(err => {
    if (!err?.attr) return;
    if (err.attr === 'non_field_errors') {
      setError('root.serverError', { type: 'custom', message: err.detail });
    } else {
      setError(err.attr, { type: 'custom', message: err.detail });
    }
  });
};

export { setFormError };
